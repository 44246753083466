#main__register {
  background-image: url("../../images/logos/main_logo.png");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: auto 40px;
  background-position: center top;
  padding-top: 70px;
  text-align: center;
  margin: auto;
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}
#main__register h1 {
  font-size: 2.5em;
}
#main__register form {
  margin: 90px auto;
}
#main__register form input[type=text] {
  display: block;
  margin: 7px auto;
  margin-bottom: 30px;
}
#main__register form input[type=submit] {
  display: block;
  margin: 30px auto;
}
#main__register #confirm__footer {
  margin-bottom: 30px;
}
#main__register #confirm__footer p {
  font-size: 14px;
  color: #aaa;
  margin: 0;
}

#main__confirm {
  background-image: url("../../images/logos/main_logo.png");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: auto 40px;
  background-position: center top;
  padding-top: 70px;
  text-align: center;
  margin: auto;
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}
#main__confirm h1 {
  font-size: 2.5em;
}
#main__confirm form {
  margin: 90px auto;
}
#main__confirm form #confirm__numbers {
  display: flex;
  width: 350px;
  margin: auto;
}
#main__confirm form #confirm__numbers > div {
  margin: 7px;
  margin-bottom: 30px;
}
#main__confirm form #confirm__numbers > div input[type=text].numbers__number {
  display: block;
  width: 100%;
  text-align: center;
  margin: 0;
}
#main__confirm form input[type=submit] {
  display: block;
  margin: 30px auto;
}
#main__confirm #register__footer {
  margin-bottom: 30px;
}
#main__confirm #register__footer p {
  font-size: 14px;
  color: #aaa;
  margin: 0;
}

#main__welcome {
  background-image: url("../../images/logos/logo_black_normal.png");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: auto 120px;
  background-position: center 30px;
  background-color: white;
  padding-top: 70px;
  text-align: center;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}
#main__welcome h1 {
  font-size: 2.5em;
}
#main__welcome form {
  text-align: center;
  margin: 90px auto;
  width: 700px;
}
#main__welcome form > div {
  display: flex;
}
#main__welcome form label {
  display: inline-block;
}
#main__welcome form input[type=text] {
  margin: 7px auto;
  margin-bottom: 15px;
}
#main__welcome form #color {
  display: block;
}
#main__welcome form #color select {
  display: flex;
  margin: 7px auto;
  margin-bottom: 15px;
}
#main__welcome form input[type=submit] {
  display: block;
  margin: 30px auto;
}
#main__welcome #welcome__footer {
  margin-bottom: 30px;
}
#main__welcome #welcome__footer p {
  font-size: 14px;
  color: #aaa;
  margin: 0;
}

#main__login {
  text-align: center;
  display: flex;
  height: 100%;
}
#main__login #login__left {
  width: 60%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 50px 0 30px;
  background: #F8F8F8;
  overflow-y: auto;
  box-shadow: rgba(0, 0, 0, 0.4) 0 0 10px;
  z-index: 10;
}
#main__login #login__right {
  width: 40%;
  background-image: url("../../images/home/smartfarm_view.jpg");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}
#main__login #login__logo {
  background-image: url("../../images/logos/sticker_smartfarm.png");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: auto 120px;
  background-position: center top;
  padding-top: 120px;
}
#main__login h1 {
  font-size: 2.5em;
  font-weight: normal;
  margin-bottom: 30px;
}
#main__login form {
  background: white;
  width: 50%;
  margin: auto;
  border-radius: 10px;
  padding: 30px;
}
#main__login form input[type=text] {
  display: block;
  margin: 7px auto;
}
#main__login form button {
  display: block;
  margin: 30px auto;
  height: 42px;
  width: 144px;
}
#main__login #login__footer {
  margin-bottom: 30px;
}
#main__login #login__footer p {
  font-size: 14px;
  color: #aaa;
  margin: 0;
}
#main__login .connection-error-message {
  color: #F9224B;
  margin-top: 5px;
}

#main__profile h2 {
  text-align: left;
}
#main__profile .flex {
  display: flex;
  justify-content: center;
}
#main__profile .flex:first-child {
  align-items: center;
}
#main__profile .flex:first-child > div:last-child {
  width: 65%;
  margin-top: 0;
}
#main__profile .flex > div:first-child {
  margin-right: 15px;
}
#main__profile .flex > div:last-child {
  margin-left: 15px;
}
#main__profile form > div {
  display: flex;
  justify-content: space-between;
}
#main__profile form > div > div {
  width: 48%;
}
#main__profile form > div.form-3 > div {
  width: 30.7%;
}
#main__profile form > div.form-3 > div label {
  width: 100% !important;
}
#main__profile form label {
  display: inline-block;
}
#main__profile form input[type=text], #main__profile form input[type=password], #main__profile form select {
  margin: 2px auto 7px;
  width: 100%;
}
#main__profile form input[type=submit], #main__profile form button {
  display: block;
  margin: 15px 0;
}
#main__profile form #f-digit-code {
  width: 100%;
  text-align: center;
}
#main__profile #profile__footer {
  margin-bottom: 30px;
}
#main__profile #profile__footer p {
  font-size: 14px;
  color: #aaa;
  margin: 0;
}

#main__organization {
  display: flex;
  align-items: stretch;
}
#main__organization > div:first-child {
  width: 60%;
}
#main__organization > div:first-child .block {
  margin-right: 30px;
}
#main__organization > div:first-child .block:first-child {
  margin-top: 0;
}
#main__organization > div:last-child {
  width: 40%;
}
#main__organization label {
  width: fit-content;
}
#main__organization th {
  text-align: center;
}
#main__organization td label {
  display: block;
  margin: 0 auto;
}
#main__organization .stock-alert--block {
  display: flex;
  flex-direction: column;
  width: 15%;
}
#main__organization .stock-alert--button-block {
  display: flex;
}
#main__organization .working-hours__input input[type=text] {
  margin: 0;
}
#main__organization .working-hours__input input[type=text]:focus {
  transform: none;
}
#main__organization button {
  margin-top: 15px;
}
#main__organization .organization__map {
  position: relative;
  width: 100%;
  min-height: 500px;
  border-radius: 4px;
  overflow: hidden;
}

.rs-checkbox-checker label {
  font-size: 1em;
}

.members-message-warning {
  padding: 10px;
  color: #ec6b22;
}
.members-message-warning svg > path {
  fill: #ec6b22;
}

#main__unregister {
  background: blue;
}

#main__connectivity .api-form {
  margin-top: 20px;
  display: flex;
  align-items: end;
}

.api-form-button {
  margin-left: 15px;
  margin-right: 15px;
  background-color: #00CAC0 !important;
  color: white;
}
.api-form-button svg > path {
  fill: white;
}

.api-button {
  margin-right: 15px;
}

.api-update-button {
  background-color: #00CAC0 !important;
  color: white;
}

.api-warning-button {
  background-color: #ec6b22 !important;
  color: white;
}
